@import 'mixins';

// Variables
$primary-font: 'Josefin Sans',
sans-serif;
$secondary-font: 'Droid Serif',
serif;
$icon-font: 'themefisher-font' !important;

/*--
    Common Css
--*/
body {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  color: #3b4045;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin: 0px;
}

h1,
.h1 {
  font-size: 44px;
  line-height: 56px;
}

h2,
.h2 {
  font-size: 30px;
  line-height: 35px;
}

h3,
.h3 {
  font-size: 23px;
  line-height: 25px;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

p {
  font-size: 18px;
  line-height: 25px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.mt-100 {
  margin-top: 100px;
}

.bg-1 {
  background-image: url(../images/call-to-action.jpg);
}

.bg-orange {
  background: #f9a743;
}

.bg-opacity {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}

a:focus,
.btn:focus,
.btn:active:focus {
  box-shadow: none;
  outline: 0 none;
}

a,
a:active,
a:focus,
a:active {
  text-decoration: none;
}

.section {
  padding: 100px 0;
}

.font-size-60 {
  font-size: 60px;
  line-height: 1.2;
  @include mobile {
    font-size: 40px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-40 {
  margin-bottom: 40px;
}

/*--
    custom heading
--*/
.text-center {
  text-align: center;
}

.heading {
  text-align: center;
  color: #777777;
  padding-bottom: 70px;

  h2 {
    color: #3c3c3c;
    padding-bottom: 15px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }

  p {
    font-size: 17px;
    line-height: 24px;
    font-weight: 300;
  }
}

.subheading {
  color: #3c3c3c;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
}

.btn {
  border-radius: 0;
}

.btn-main {
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  padding: 15px 40px;
  display: inline-block;
  background-color: #f9a743;
  box-shadow: 0 15px 40px rgba(249, 167, 68, 0.5);
  background-image: linear-gradient(bottom, rgba(255, 239, 206, 0) 0%, rgba(255, 239, 206, 0.25) 100%);

  &:hover,
  &:focus {
    background-color: #f9a743;
    color: #fff;
  }
}

.btn-main-sm {
  padding: 12px 35px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

/*Preloader*/
#preloader-wrapper {
  background-color: #fff;
  bottom: auto;
  height: 100%;
  left: auto;
  position: fixed;
  right: auto;
  top: auto;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre-loader {
  width: 130px;
  height: 130px;
  background: #f9a743;
  border-radius: 50%;
  animation: loading 6s infinite reverse linear;
  position: relative;
  font-family: $icon-font;

  &:before {
    content: "\f3bd";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    font-size: 40px;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@keyframes loading {

  /*  */
  0% {
    box-shadow: inset 0 0 0 80px #f9a743;
  }

  8.49% {
    background: #f9a743;
    box-shadow: inset 0 0 0 160px #f9a743;
  }

  /* orange */
  8.5% {
    box-shadow: inset 0 0 0 0 #ff8133;
  }

  24.99% {
    background: #ff8133;
    box-shadow: inset 0 0 0 160px #ff8133;
  }

  /* yellow */
  25% {
    box-shadow: inset 0 0 0 0 #ffbe1a;
  }

  41.49% {
    background: #ffbe1a;
    box-shadow: inset 0 0 0 160px #ffbe1a;
  }

  /*  */
  41.5% {
    box-shadow: inset 0 0 0 0 #f9a743;
  }

  57.99% {
    background: #f9a743;
    box-shadow: inset 0 0 0 160px #f9a743;
  }

  /* blue */
  58% {
    box-shadow: inset 0 0 0 0 #00b5d9;
  }

  74.49% {
    background: #00b5d9;
    box-shadow: inset 0 0 0 160px #00b5d9;
  }

  /* purple */
  74.5% {
    box-shadow: inset 0 0 0 0 #9772e5;
  }

  90.99% {
    background: #9772e5;
    box-shadow: inset 0 0 0 160px #9772e5;
  }

  /*  */
  91% {
    box-shadow: inset 0 0 0 0 #f9a743;
  }

  100% {
    background: #f9a743;
    box-shadow: inset 0 0 0 80px #f9a743;
  }
}

/* navigation */
.navbar {
  &-brand {
    color: #444;
    font-size: 25px;
    margin: 0;
    line-height: 1;
  }
}

.nav-link {
  font-size: 14px;
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: #444;
}

/*--
    feature start
--*/
/*--
    Feature-list start
--*/
.feature-list {
  .btn-main {
    margin-top: 15px;
  }
}

/*--
  Gallery start
--*/
.gallery {
  padding-bottom: 100px;

  .owl-item {
    overflow: hidden;
    position: relative;
  }

  .block {
    padding: 20px;

    &:hover .gallery-overlay {
      transform: scale(1);
      opacity: 1;
    }
  }

  .gallery-overlay {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    top: 15px;
    background: rgba(255, 255, 255, 0.85);
    text-decoration: none;
    color: inherit;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 2;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

    .gallery-popup {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: #f9a743;
      padding: 5px 10px;
      border-radius: 40px;
      width: 50px;
      height: 50px;

      i {
        color: #fff;
        font-size: 30px;
        line-height: 40px;
        display: inline-block;
      }
    }
  }
}

/*--
    service start
--*/
.testimonials {
  .testimonial-block {
    padding: 0 10px;

    i {
      font-size: 40px;
      display: inline-block;
      margin-bottom: 20px;
      color: #f9a743;
    }

    p {
      font-family: $secondary-font;
      font-size: 14px;
      color: #777;
    }

    .author-details {
      margin-top: 30px;

      img {
        border-radius: 50%;
        width: 50px;
      }

      h4 {
        font-weight: 700;
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }
}

/*--
    call-to-action start
--*/
.call-to-action {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.input-group {
  margin-top: 30px;
  position: relative;

  .btn-submit {
    padding: 10.5px 40px !important;
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    top: 0;
    right: 0;
  }

  input {
    height: 50px;
    border-radius: 40px;
    border: none;
    padding-left: 15px;
    box-shadow: none;
    display: table-cell;
  }
}

.footer-menu{
  a{
    font-size: 14;
    font-weight: 700;
    color: #444;
    padding: 10px;
  }
}

footer {
  text-align: center;
  background: #f4f4f4;
  padding: 50px 0px;

  .footer-logo {
    color: #555;
    display: block;
  }

  p.copyright-text {
    font-weight: 300;
    font-size: 12px;
    color: #888;
    letter-spacing: 3px;
    margin-top: 15px;

    a {
      color: #282828;

      &:hover {
        text-decoration: none;
      }
    }
  }
}